import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/internal/operators/catchError';
import {tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class apiCalls{
    private headers=new HttpHeaders({'Content-Type':'application/json'});

    constructor(private http:HttpClient){}
    
    getMeetupDetails():Observable<any[]>{
         const url="https://api.meetup.com/We-Mean-To-Clean/events";
        // const url="https://api.meetup.com/Delhi-Street-Art-Meetup/events";
        return this.http.jsonp<any[]>(url,'callback')
        .pipe(catchError(this.handleError));
    }

    private handleError(err: HttpErrorResponse) {
        console.log(err);
        return Observable.throw(err.error() || 'Server error');
    }
}



