
<section class="menu cid-swfEENVNJh" once="menu" id="menu">
   <nav class="navbar navbar-expand beta-menu navbar-dropdown align-items-center navbar-fixed-top navbar-toggleable-sm"
      style=" background-color: #1ABC9C;">
      <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
         <div class="hamburger">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
         </div>
      </button>
      <div class="menu-logo">
         <div class="navbar-brand">
            <span class="navbar-logo">
               <a href="#home">
                  <img src="assets/images/favicon.png" alt="WMTC logo" title="" style="height: 2rem;">
               </a>
            </span>
            <!-- <span class="navbar-caption-wrap"><a class="navbar-caption display-4" href="http://wmtc.org.in" style="color:#1ABC9C;">WMTC COVID Relief Initiative</a></span> -->
         </div>
      </div>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
         <ul class="navbar-nav nav-dropdown nav-right" data-app-modern-menu="true">
            <li class="nav-item">
               <a class="nav-link link display-4" href="#home" style="color: white;">
                  <span class=" mbr-iconfont mbr-iconfont-btn"></span>Home</a>

            <li class="nav-item">
               <a class="nav-link link display-4" href="#about" aria-expanded="false" style="color: white;" >
                  <span class=" mbr-iconfont mbr-iconfont-btn"></span>
                  What We Do</a>
            </li>
            <li class="nav-item">
               <a class="nav-link link display-4" href="#upcoming" style="color: white; ">
                  <span class=" mbr-iconfont mbr-iconfont-btn"></span>Upcoming</a>
            </li>

            <!-- <li class="nav-item">
               <a class="nav-link link display-4" href="#news" style="color: white;">
                  <span class=" mbr-iconfont mbr-iconfont-btn"></span>News and Media</a>
            </li> -->

            <!-- <li class="nav-item">
               <a class="nav-link link display-4" href="https://wemeantoclean.blogspot.com" target="_blank" style="color: white;">
                  <span class=" mbr-iconfont mbr-iconfont-btn"></span>Blog</a>
            </li> -->

            <li class="nav-item">
               <!-- <a class="nav-link link display-4" routerLink="join" routerLinkActive="active" style="color: white;" (click)="showJoinPage()">
                  <span class=" mbr-iconfont mbr-iconfont-btn"></span>Join Us</a> -->
                  <a class="nav-link link display-4" href="#whyVolunteer" style="color: white; ">
                     <span class=" mbr-iconfont mbr-iconfont-btn"></span>Why Volunteer?</a>

                     <li class="nav-item">
                        <!-- <a class="nav-link link display-4" routerLink="join" routerLinkActive="active" style="color: white;" (click)="showJoinPage()">
                           <span class=" mbr-iconfont mbr-iconfont-btn"></span>Join Us</a> -->
                           <a class="nav-link link display-4" href="#whyVolunteerWithUs" style="color: white; ">
                              <span class=" mbr-iconfont mbr-iconfont-btn"></span>Why Volunteer with Us?</a>
                     </li>

                     <li class="nav-item">
                        <!-- <a class="nav-link link display-4" routerLink="join" routerLinkActive="active" style="color: white;" (click)="showJoinPage()">
                           <span class=" mbr-iconfont mbr-iconfont-btn"></span>Join Us</a> -->
                           <a class="nav-link link display-4" href="#news" style="color: white; ">
                              <span class=" mbr-iconfont mbr-iconfont-btn"></span>News</a>
                     </li>

                     <li class="nav-item">
                        <!-- <a class="nav-link link display-4" routerLink="join" routerLinkActive="active" style="color: white;" (click)="showJoinPage()">
                           <span class=" mbr-iconfont mbr-iconfont-btn"></span>Join Us</a> -->
                           <a class="nav-link link display-4" href="#joinUs" style="color: white; ">
                              <span class=" mbr-iconfont mbr-iconfont-btn"></span>Join Us</a>
                     </li>            
            
         </ul>


      </div>
   </nav>
</section>
<br>
<climate-clock></climate-clock>

<!-- <div *ngIf="join">
   <router-outlet></router-outlet> -->
   <!-- <section class="cid-swfEH7rnkz mbr-fullscreen mbr-parallax-background" id="joinsec1">

      <div class="mbr-overlay" style="opacity: 0.8; background-color: rgb(35, 35, 35);"></div>
   
      <div class="container align-left">
         <div class="row justify-content-md-center">
            <div class="mbr-white col-md-10">
               <h1 class="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1">
                  Why Volunteer?
                 </h1>
   
               <p class="mbr-text pb-3 mbr-fonts-style display-5">
                 Volunteering has immense benefits. Some of them are as follows:
               </p>
               <ul class="mbr-text pb-3 mbr-fonts-style display-5" style="list-style-type:disc;">
                 <li>Volunteering helps you build/ hone soft skills such as teamwork, coordination, communication, 
                    listening and conflict management. They not only make you a better individual but also add a great 
                    value to your resume. </li>
                    <br>
                   <li>Volunteering helps make new friends and expand your social as well as professional network. 
                      This increased socializing activity also helps reduce stress and anxiety. Additionally, 
                      it helps in becoming more emotionally stable.</li>
                      <br>
                   <li>Most importantly, volunteering gives a sense of purpose in life. 
                      An objective that helps you lead a meaningful life while also making a positive impact on the lives of others.</li>
                 </ul>
            </div>
         </div>
      </div> -->
   <!-- </section> -->
   
   
   <!-- <section class="mbr-section form1 cid-swfEXXThIV" id="joinsec2">
   
      <div class="container align-center">
          <div class="row justify-content-md-left">
              <div class="mbr-white col-md-10">
                  <h1 class="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1" style="color: #1ABC9C; text-align: left;">Why volunteer with us?</h1>
               </div>
               <br><br><br>
  
               <div class="mbr-white col-md-12">
                 <p class="mbr-text pb-3 mbr-fonts-style display-5" style="color: black; text-align: left;">
                   
                      Volunteering with We Mean To Clean will expose you to a diverse range of people: </p>
                      <ul class="mbr-text pb-3 mbr-fonts-style display-5" style="color: black;text-align: left;list-style-type:disc;">
                       <li>MBAs; corporate professionals
                       </li>
                       <li>Software and mechanical engineers
                       </li>
                       <li>Defence </li>
                       <li> Lawyers</li>
                       <li>Teachers </li>
                       <li>Chefs </li>
                       <li>Artists </li>
                       <li>College/School Students </li>
                       
                       </ul>
                           
                          <p class="mbr-text pb-3 mbr-fonts-style display-5" style="color: black; text-align: left;">
                         Such exposure helps you build different perspectives on a given situation to help you improve
                             your thinking and problem solving ability.
                       
                            <br> <br>
                         Whether you wish to develop/ improve your skill across project management, negotiation, people management, 
                            content writing, social media marketing, waste management know-how, knowledge of Delhi’s native trees or if 
                            you simply wish to do your bit for soul-satisfaction, we have something for everyone!
                       
                       <br> <br>
                       Moreover, we also have access to domain experts in our areas of operations. So, we work in an educated manner 
                          allowing all of us to take scientifically-correct steps.
                     
                     <br> <br>
                    We Mean To Clean also provides certificates to volunteers as per a defined set of terms and conditions.
                     
                 </p>
                 
              </div>
              </div>
          </div>
   
   </section>
   
  
   <section class="mbr-section form1 cid-swfEXXThIV" id="joinsec3" style="background: #1ABC9C;">
  
     <div class="container align-center">
         <div class="row justify-content-md-left">
             <div class="mbr-white col-md-10">
                 <h1 class="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1" style="color: white; text-align: left;">Joining us</h1>
              </div>
              <br><br><br>
  
              <div class="mbr-white col-md-12">
                <p class="mbr-text pb-3 mbr-fonts-style display-5" style="color: white; text-align: left;">
                  
                 <a target="blank" href="https://www.meetup.com/We-Mean-To-Clean/ " style="color: white;"><strong><u>Meetup.com</u></strong></a> 
                  is our primary platform for registration of members and scheduling of events - spotfixes,
                  cleanliness drives, awareness programs etc. You'll be taken to
                 <u><a target="blank" href="https://www.meetup.com/We-Mean-To-Clean/ " style="color: white;"><strong>our meetup page</strong></a></u>
                   on this platform and you can sign up there. 
                   The site will ask you to activate your account by verifying your email ID. Once you're done, 
                   you'll have our joining form with a few basic questions. It's all very simple!
              </p>
                     
                
             </div>
                
             </div>
         </div>
  
  </section> -->
  
   
<!-- </div> -->

<!-- <div *ngIf="!join"> -->
<section class="cid-swfEH7rnkX mbr-fullscreen mbr-parallax-background" id="home">

   <div class="mbr-overlay" style="opacity: 0.8; background-color: rgb(35, 35, 35);"></div>

   <div class="container align-left">
      <div class="row justify-content-md-center">
         <div class="mbr-white col-md-10">
            <h1 class="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1">We Mean To Clean</h1>

            <p class="mbr-text pb-3 mbr-fonts-style display-5">
               We Mean To Clean volunteers are a team of self-motivated action-oriented individuals coming from 
               different walks of life. These volunteers believe that keeping our city and country clean isn’t only 
               the responsibility of the government alone but that of citizens like us too!
            </p>
            <p class="mbr-text pb-3 mbr-fonts-style display-5">
               Moreover, if we have to achieve Swachh Bharat, protect our environment, combat global warming and 
               climate change, we’ll all need to step up and take tangible actions. Hence, we engage in 'shramdaan' 
               or donation of effort on a weekly/ fortnightly basis.
            </p>

            <div class="mbr-section-btn align-center">
               <a class="btn btn-md display-3" href="#joinUs" style="background-color: #1ABC9C; color: white;"><b>Sounds interesting? Join us!</b></a>
               <!-- <a class="btn btn-md btn-white-outline display-4" style="background-color: #1ABC9C; color: white;" href="#joinUs">Sounds interesting, join us!</a> -->
               </div>
               <div class="container align-center">
               <a href="https://www.facebook.com/WeMeanToCleanMeetup" class="fa fa-facebook" target="_blank"></a>
                     <a href="https://twitter.com/WeMeanToClean" class="fa fa-twitter" target="_blank"></a>
                     <a href="https://www.instagram.com/wemeantoclean/" class="fa fa-instagram" target="_blank"></a>
                     <a href="https://www.linkedin.com/company/we-mean-to-clean/" class="fa fa-linkedin" target="_blank"></a>
                     <!-- <a href="https://www.meetup.com/We-Mean-To-Clean/" class="fa fa-meetup" target="_blank"></a> -->
                     <a href="https://www.youtube.com/channel/UCeXb3QFHRoD1z1huwR3Q-AQ" class="fa fa-youtube" target="_blank"></a>
                     <a href="https://wemeantoclean.blogspot.com" class="fa fa-rss" target="_blank"></a>
            </div>
         </div>
      </div>
   </div>
   <!-- <div class="mbr-arrow hidden-sm-down" aria-hidden="true">
       <a href="#next">
           <i class="mbri-down mbr-iconfont"></i>
       </a>
   </div> -->
</section>

<section class="mbr-section form1 cid-swfEXXThIV" id="about">

    

   <!-- <div class="mbr-overlay" style="opacity: 0.5; background-color: rgb(35, 35, 35);"></div> -->

   <div class="container align-left">
       <div class="row justify-content-md-left">
           <div class="mbr-white col-md-10">
               <h1 class="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1" style="color: #1ABC9C; text-align: left;">What We Do</h1>
            </div>
            <br><br><br>
             <div class=" mbr-text pb-3 mbr-fonts-style display-5 col-md-12" style="font-size: large;text-align: left;">
                   <p><b> The abbreviation of our name - WMTC - itself has our focus areas!</b></p>
                    </div>

            <div class="card">
               <img src="assets/images/1.jpg" alt="Avatar" style="width:100%">
               <div class="container">
                  <br>
                 <h4><b>WM</b></h4>
                <b> <p>Waste Management</p></b>
                 <p>We conduct waste management awareness programs and also help implement/ execute the same.</p>
               </div>
             </div>
             <div class="card">
               <img src="assets/images/2.jpg" alt="Avatar" style="width:100%">
               <div class="container">
                  <br>
                 <h4><b>T</b></h4>
                 <b><p>Tree Plantation</p></b>
                 <p>Native tree plantation and we also water our saplings regularly.</p>
               </div>
             </div>
             <div class="card">
               <img src="assets/images/3.jpg" alt="Avatar" style="width:100%">
               <div class="container">
                  <br>
                 <h4><b>C</b></h4>
               <b><p>Cleanup Drives</p></b>
                 <p>Cleaning a public place that's a victim of open garbage dumping/ open urination/ defecation.</p>
               </div>
             </div>
           
<!--
                   <div class="col-md-5"> 
                   <ul style="list-style-type:disc;">
                   <li>WM - Waste Management </li>
                     <li>T - Tree Plantation </li>
                     <li>C - Cleanup Drives</li>
                   </ul>
                  </div> -->
                   <!-- <div class="col-md-6">
                   <ngb-carousel #carousel [interval]="5000" [pauseOnHover]="pauseOnHover" [pauseOnFocus]="pauseOnFocus" (slide)="onSlide($event)" [showNavigationArrows]="showNavigationArrows" [showNavigationIndicators]="showNavigationIndicators">
                     <ng-template ngbSlide *ngFor="let cap of caption; index as i"> -->
                       <!-- <div class="carousel-caption">
                         <h3>My slide {{i + 1}} title</h3>
                       </div> -->
                       <!-- <a href="https://www.google.fr/?q=Number+{{i+1}}" target="_blank" rel="nofollow noopener noreferrer"> -->
                        <!-- <div class="md-col-5"> -->
                        <!-- <div class="picsum-img-wrapper col-md-5">
                           <img class="img-center" [src]="'assets/images/'+(i+1)+'.jpg' | safe" alt="What We Do"> -->
                         <!-- </div> -->
                        <!-- </div> -->
                        <!-- <div class="md-col-5 offset-2"  style="padding: 170px 0;">
                           <p>{{cap}}</p>
                           </div> -->
                       <!-- </a> -->
                     <!-- </ng-template>
                   </ngb-carousel>
                    </div>
                  <div class="col-md-6">
                     <ngb-carousel #carousel [interval]="5000" [pauseOnHover]="pauseOnHover" [pauseOnFocus]="pauseOnFocus" (slide)="onSlide($event)" [showNavigationArrows]="showNavigationArrows" [showNavigationIndicators]="showNavigationIndicators">
                        <ng-template ngbSlide *ngFor="let cap of caption; index as i"> -->
                          <!-- <div class="carousel-caption">
                            <h3>My slide {{i + 1}} title</h3>
                          </div> -->
                          <!-- <a href="https://www.google.fr/?q=Number+{{i+1}}" target="_blank" rel="nofollow noopener noreferrer"> -->
                           <!-- <div class="md-col-5">
                           <div class="picsum-img-wrapper col-md-5">
                              <img [src]="'assets/images/'+(i+1)+'.jpg' | safe" alt="What We Do" style="width: 450px; height: 450px; margin-left: 50px; margin-right: auto; ">
                            </div>
                           </div> -->
                           <!-- <div style="padding: 70px 0;">
                              <p>{{cap}}</p>
                              </div> -->
                          <!-- </a> -->
                        <!-- </ng-template>
                      </ngb-carousel>-->
                  <!-- </div>  -->
                     <br><br>
                     <div class=" mbr-text pb-3 mbr-fonts-style display-5 col-md-12"  style="font-size: large;text-align: left;">
                        <!-- <br><br> -->
                        <p><b>Our volunteering efforts not only contribute towards the Swachh Bharat Abhiyaan (Clean India Mission)
                        but they also aim to impact the following United Nations Sustainable Development Goals (UN SDG):</b></p>
                        <ul style="list-style-type:disc;">
                           <li> Goal 3 - Good Health and Well-being</li>
                        <li>Goal 8 - Decent Work and Economic Growth</li>
                           <li>Goal 13 - Climate Action</li>
                              <li> Goal 14 - Life under Water</li>
                                 <li>Goal 15 - Life on Land</li>
                        </ul>
                  </div>
              
           </div>
       </div>
       <div class="mbr-section-btn align-center">
         <a class="btn btn-md display-3" href="#joinUs" style="background-color: #1ABC9C; color: white;"><b>Sounds interesting? Join us!</b></a>
         <!-- <a class="btn btn-md btn-white-outline display-4" style="background-color: #1ABC9C; color: white;" href="#joinUs">Sounds interesting, join us!</a> -->
         </div>

</section>


<!-- <section class="mbr-section form1 cid-swfEXXThIV" id="about"> -->

    

   <!-- <div class="mbr-overlay" style="opacity: 0.5; background-color: rgb(35, 35, 35);"></div> -->

   <!-- <div class="container align-center">
       <div class="row justify-content-md-left">
           <div class="mbr-white col-md-10">
               <h1 class="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1" style="color: #1ABC9C; text-align: left;">What We Do</h1>
            </div>
            <br><br><br><br>
            <div class=" col-md-10 mbr-text pb-3 mbr-fonts-style display-5" style="font-size: large;text-align: left;">
               <p><b> The abbreviation of our name - WMTC - itself has our focus areas!</b></p>
                </div>
                <div class="col-md-6 mbr-text pb-3 mbr-fonts-style display-5" style="font-size: large;text-align: left;">
                  <img src="assets/images/WM.jpg" alt="Waste Management Worshop" style="width: 75%;">
                   </div>
            <div class="col-md-6 offset-2 mbr-text pb-3 mbr-fonts-style display-5" style="font-size: large;text-align: left;">
                   <br>
                   <p>WM - We conduct Waste Management awareness programs and also help implement/execute the same.
                  </p>
                    </div>
                    <div class="col-md-6 mbr-text pb-3 mbr-fonts-style display-5" style="font-size: large;text-align: left;">
                     <img src="assets/images/T.jpg" alt="Waste Management Worshop" style="width: 75%;">
                      </div>
               <div class="col-md-6 offset-2 mbr-text pb-3 mbr-fonts-style display-5" style="font-size: large;text-align: left;">
                      <br>
                      <p>T - Native tree plantation and we also water our saplings regularly.
                     </p>
                       </div>
                       <div class="col-md-6 mbr-text pb-3 mbr-fonts-style display-5" style="font-size: large;text-align: left;">
                        <img src="assets/images/C.jpg" alt="Waste Management Worshop" style="width: 75%;">
                         </div>

                  <div class="col-md-6 offset-2 mbr-text pb-3 mbr-fonts-style display-5" style="font-size: large;text-align: left;">
                         <br>
                         <p>C - Cleaning a public place that's a victim of open garbage dumping/ open urination/ defecation.
                        </p>
                          </div>
                          <br><br>
                     <div class=" mbr-text pb-3 mbr-fonts-style display-5"  style="font-size: large;text-align: left;">
                        <p><b>Our volunteering efforts not only contribute towards the Swachh Bharat Abhiyaan (Clean India Mission)
                        but they also aim to impact the following United Nations Sustainable Development Goals (UN SDG):</b></p>
                        <ul style="list-style-type:disc;">
                           <li> Goal 3 - Good Health and Well-being</li>
                        <li>Goal 8 - Decent Work and Economic Growth</li>
                           <li>Goal 13 - Climate Action</li>
                              <li> Goal 14 - Life under Water</li>
                                 <li>Goal 15 - Life on Land</li>
                        </ul>
                  </div>
              
           </div>
       </div>
       <div class="mbr-section-btn align-center">
         <a class="btn btn-md display-3" href="#joinUs" style="background-color: #1ABC9C; color: white;"><b>Sounds interesting? Join us!</b></a>
          <a class="btn btn-md btn-white-outline display-4" style="background-color: #1ABC9C; color: white;" href="#joinUs">Sounds interesting, join us!</a> -->
         <!-- </div>

</section> --> 

<section class="mbr-section form1 cid-swfEXXThIV" id="upcoming" style="background: #1ABC9C;">

    

   <!-- <div class="mbr-overlay" style="opacity: 0.5; background-color: rgb(35, 35, 35);"></div> -->

   <div class="container align-left" >
       <div class="row justify-content-md-left" style="color: white;">
           <div class="mbr-white col-md-10">
               <h1 class="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1" style="color: white; text-align: left;">Upcoming</h1>
            </div>
            <br><br><br><br>
            <!-- <br><br><br> -->
            
            <!-- <div class="mbr-white col-md-6">
               <div *ngFor="let meetup of meetupList">
                  <div *ngIf="meetup.data.length>0; else message"> -->
                  <!-- <div *ngFor="let data of meetup.data"> -->
                  
                     
                     <!-- <table class="mbr-text pb-3 mbr-fonts-style display-5" style="width:100%; font-size: large; text-align: left;">
                        <tr>
                          <th> -->
                           <!-- <ul style="list-style-type:disc;"> -->
                              <!-- <b>{{meetup.data[0].name}}</b><br><br>
                           <b>Date:</b> {{meetup.data[0].local_date|date:'fullDate'}}<br><br>
                           <b>Time:</b> {{meetup.data[0].local_time}} AM<br><br> -->
                           <!-- <b>Venue:</b> {{meetup.data[0].venue.name}}, {{meetup.data[0].venue.city}}, {{meetup.data[0].venue.localized_country_name}}<br><br> -->
                           
                           <!-- <iframe id="google-map"
                           [src]="'https://maps.google.com/maps?q='+meetup.data[0].group.lat+','+meetup.data[0].group.lon+'&hl=es;z=14&amp;output=embed' | safe"
                           width="350" 
                           height="400" 
                           frameborder="0"
                         ></iframe> -->
                           
                           <!-- <div style="font-weight: normal;" [innerHtml]="data.description.replaceAll('\\','')"></div> -->
                         
                              <!-- <br><br><br>
                  </div></div></div> -->
               <!-- </div> -->
                  <!-- <div class="mbr-white col-md-6">
                     <div *ngFor="let meetup of meetupList">
                        <div *ngIf="meetup.data.length>0"> -->
                        <!-- <div *ngFor="let data of meetup.data"> -->
                        
                           
                           <!-- <table class="mbr-text pb-3 mbr-fonts-style display-5" style="width:100%; font-size: large; text-align: left;">
                              <tr>
                                <th> -->
                                 <!-- <ul style="list-style-type:disc;"> -->
                                    <!-- <b>{{data.name}}</b><br><br>
                                 <b>Date:</b> {{data.local_date|date:'fullDate'}}<br><br>
                                 <b>Time:</b> {{data.local_time}} AM<br><br>
                                 <b>Venue:</b> {{data.venue.name}}, {{data.venue.city}}, {{data.venue.localized_country_name}}<br><br> -->
                                 
                                 <!-- <div style="font-weight: normal;" [innerHtml]="meetup.data[0].description.replaceAll('\\','')"></div>
                               
                                 <br><br><br>
                        </div></div></div> -->
                     <!-- </div> -->
                     <div class="mbr-white col-md-12">
               <div *ngFor="let meetup of meetupList">
                  <div *ngIf="meetup.data.length>0; else message">
                  <div *ngFor="let data of meetup.data">
                  
                     
                     <!-- <table class="mbr-text pb-3 mbr-fonts-style display-5" style="width:100%; font-size: large; text-align: left;">
                        <tr>
                          <th> -->
                           <!-- <ul style="list-style-type:disc;"> -->
                              <!-- <div class="card"> -->
                                 <!-- <img src="assets/images/3.jpg" alt="Avatar" style="width:100%"> -->
                                 <!-- <div class="container">
                                    <br>
                                   <h4><b>{{data.name}}</b></h4>
                                   <p><b>Date: </b>{{data.local_date|date:'fullDate'}}</p>
                                   <p><b>Time: </b>{{data.local_time}} AM </p> -->
                                   <!-- <div class="btn btn-md display-3"> <a target="_blank" href="{{data.link}}">Details</a></div> -->
                                   <!-- <a target="_blank" class="btn btn-md display-3" href="{{data.link}}" style="background-color: white; color: 1ABC9C;"><b>Find out more and RSVP!</b></a>
                                 </div>
                               </div> -->

                              <b>{{data.name}}</b><br><br>
                           <b>Date:</b> {{data.local_date|date:'fullDate'}}<br><br>
                           <b>Time:</b> {{data.local_time}} AM<br><br>
                           <p>For more details, visit here: <a target="_blank" href="{{data.link}}">{{data.link}}</a></p>

                           <hr>
                           <!-- <b>Venue:</b> {{meetup.data[1].venue.name}}, {{meetup.data[1].venue.city}}, {{meetup.data[1].venue.localized_country_name}}<br><br> -->
                           
                           <!-- <iframe id="google-map"
                           [src]="'https://maps.google.com/maps?q='+meetup.data[1].group.lat+','+meetup.data[1].group.lon+'&hl=es;z=14&amp;output=embed' | safe"
                           width="350" 
                           height="400" 
                           frameborder="0"
                         ></iframe> -->
                           
                           <!-- <div style="font-weight: normal;" [innerHtml]="data.description.replaceAll('\\','')"></div> -->
                         
                           <br>
                  </div></div></div></div>
               <!-- </div> -->
                  <!-- <div class="mbr-white col-md-6">
                     <div *ngFor="let meetup of meetupList">
                        <div *ngIf="meetup.data.length>0"> -->
                        <!-- <div *ngFor="let data of meetup.data"> -->
                        
                           
                           <!-- <table class="mbr-text pb-3 mbr-fonts-style display-5" style="width:100%; font-size: large; text-align: left;">
                              <tr>
                                <th> -->
                                 <!-- <ul style="list-style-type:disc;"> -->
                                    <!-- <b>{{data.name}}</b><br><br>
                                 <b>Date:</b> {{data.local_date|date:'fullDate'}}<br><br>
                                 <b>Time:</b> {{data.local_time}} AM<br><br>
                                 <b>Venue:</b> {{data.venue.name}}, {{data.venue.city}}, {{data.venue.localized_country_name}}<br><br> -->
                                 
                                 <!-- <div style="font-weight: normal;" [innerHtml]="meetup.data[1].description.replaceAll('\\','')"></div>
                               
      
                        </div></div></div> -->
                     <!-- </div> -->
                  <ng-template #message>
       
                  <div class="mbr-white col-md-12">
                     <p class="mbr-text pb-3 mbr-fonts-style display-5" style="font-size: large; color: white; text-align: left;">
                        We are currently deciding on our next event. <br>You can join our 'admin-only' WhatsApp group to be notified of our events.<br>
                        No spam!
                     </p>
                     <div class="mbr-section-btn align-center">
                        <a class="btn btn-md display-3" href="https://chat.whatsapp.com/KUeDYjb41oD8dIhhREfLad" style="background-color: white; color: #1ABC9C;" target="_blank"><b>Join WhatsApp Group</b></a>
                        <!-- <a class="btn btn-md btn-white-outline display-4" style="background-color: #1ABC9C; color: white;" href="#joinUs">Sounds interesting, join us!</a> -->
                        </div>
                  </div>
               </ng-template>
              
           </div>
       </div>
   
</section>

<!-- </div> -->
<!-- <section class="map2 cid-sDxcN2bc4Q" id="map2-b">

    

   <div class="container">
       <div class="media-container-row">
           <div class="col-md-8">
               <div class="google-map"><iframe frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDk89J4FSunMF33ruMVWJaJht_Ro0kvoXs&amp;q=place_id:ChIJn6wOs6lZwokRLKy1iqRcoKw" allowfullscreen=""></iframe></div>
       </div>
   </div>
</div></section> -->
<!-- <section class="mbr-section form1 cid-swfEXXThIV" id="news">

   <div class="container align-center">
       <div class="row justify-content-md-left">
           <div class="mbr-white col-md-10">
               <h1 class="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1" style="color: #1ABC9C; text-align: left;">News and Media</h1>
            </div>
            <br><br><br>
                    <div class="mbr-white col-md-12">
                     <h1 class="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-5" style="color: black; text-align: left;">
                        We Mean To Clean has been featured in all forms of media - print, TV, radio and digital!
                     </h1>
                  </div>
           </div>
       </div>
</section> -->
<section class="mbr-section form1 cid-swfEXXThIV" id="whyVolunteer">

   <!-- <div class="mbr-overlay" style="opacity: 0.8; background-color: rgb(35, 35, 35);"></div> -->

   <div class="container align-left">
      <div class="row justify-content-md-center">
         <div>
         <div class="mbr-white col-md-10">
            <h1 class="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1" style="color: #1ABC9C; text-align: left;">Why Volunteer?</h1>
         </div>
         <br>
         <div class="mbr-white col-md-12">
         <p class=" mbr-text pb-3 mbr-fonts-style display-5" style="font-size: large;text-align: left; color: black;">
              Volunteering has immense benefits. Some of them are as follows:
            </p>
            <!-- <img src="assets/images/4.jpeg" style="float:left; width:300px;height:auto;margin-right: 35px;"> -->
            <ul class=" mbr-text pb-3 mbr-fonts-style display-5" style="font-size: large;text-align: left;list-style-type:disc;color: black;">
              <li>Volunteering helps you build/ hone soft skills such as teamwork, coordination, communication, 
                 listening and conflict management. They not only make you a better individual but also add a great 
                 value to your resume. </li>
                 <br>
                <li>Volunteering helps make new friends and expand your social as well as professional network. 
                   This increased socializing activity also helps reduce stress and anxiety. Additionally, 
                   it helps in becoming more emotionally stable.</li>
                   <br>
                <li>Most importantly, volunteering gives a sense of purpose in life. 
                   An objective that helps you lead a meaningful life while also making a positive impact on the lives of others.</li>
              </ul>
         </div>
         </div>
      </div>
   </div>
</section>


<section class="mbr-section form1 cid-swfEXXThIV" id="whyVolunteerWithUs" style="background: #1ABC9C;">

   <div class="container align-center">
       <div class="row justify-content-md-left">
           <div class="mbr-white col-md-10">
               <h1 class="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1" style="color: white; text-align: left;">Why Volunteer with Us?</h1>
            </div>
            <br><br><br><br>

            <div class="mbr-white col-md-12">
              <p class="mbr-text pb-3 mbr-fonts-style display-5" style="font-size: large; color: white; text-align: left;">
               <!-- <img src="assets/images/5.jpeg" style="float:left; width:280px;height:auto;margin-right: 35px;"> -->
                   Volunteering with We Mean To Clean will expose you to a diverse range of people: </p>
                   <ul class="mbr-text pb-3 mbr-fonts-style display-5" style="font-size: large;color: white;text-align: left;list-style-type:disc;">
                     
                     <li>MBAs; corporate professionals
                    </li>
                    <li>Software and mechanical engineers
                    </li>
                    <li>Defence </li>
                    <li> Lawyers</li>
                    <li>Teachers </li>
                    <li>Chefs </li>
                    <li>Artists </li>
                    <li>College/School Students </li>
                    </ul>
                    
                        
                       <p class="mbr-text pb-3 mbr-fonts-style display-5" style="font-size: large; color: white; text-align: left;">
                      Such exposure helps you build different perspectives on a given situation to help you improve
                          your thinking and problem solving ability.
                    
                         <br> <br>
                      Whether you wish to develop/ improve your skill across project management, negotiation, people management, 
                         content writing, social media marketing, waste management know-how, knowledge of Delhi’s native trees or if 
                         you simply wish to do your bit for soul-satisfaction, we have something for everyone!
                    
                    <br> <br>
                    Moreover, we also have access to domain experts in our areas of operations. So, we work in an educated manner 
                       allowing all of us to take scientifically-correct steps.
                  
                  <br> <br>
                 We Mean To Clean also provides certificates to volunteers as per a defined set of terms and conditions.
                  
              </p>
              
           </div>
           </div>
       </div>

</section>

<section class="mbr-section form1 cid-swfEXXThIV" id="news">
      
         <div class="container align-left">
             <div class="row justify-content-md-left">
                 <div class="mbr-white col-md-10">
                     <h1 class="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1" style="color: #1ABC9C; text-align: left;">News</h1>
                  </div>
                  <br><br><br>

                     <div class=" mbr-text pb-3 mbr-fonts-style display-5 col-md-4"  style="font-size: large;text-align: left;">
                     
                          <p><b>International Day for Biological Diversity: Students, citizen groups join hands for conservation</b></p>
                        <p>We Mean To Clean (WMTC), a Delhi-based volunteer group is curating a free Nature walk at Japanese Park in Rohini. This will be coupled with insights by biodiversity expert Bhuvan Chopra. “A Nature walk is a great way to learn about biodiversity while also allowing for mental rejuvenation. We’ll be applying the biodiversity concepts learnt in our plantation drives as well,” says Manish Khurana, co-founder of WMTC...<a href="https://www.hindustantimes.com/cities/delhi-news/international-day-for-biological-diversity-students-citizen-groups-join-hands-for-conservation-101653203535038.html" style="color: #1ABC9C;" target="_blank"> Read More</a></p>
                     
                        <p>Hindustan Times</p>
                        <p>22-May-2022</p>
                     
                        <hr style=" border-top: 1px solid #1ABC9C">
                     </div>  

                     <div class=" mbr-text pb-3 mbr-fonts-style display-5 col-md-4"  style="font-size: large;text-align: left;">
                     
                        <p><b>#EarthDay 2022: City youngsters vow to go green</b></p>
                        <br>
                      <p>For those who wish to step out and offer a helping hand, We Mean To Clean (WMTC), a Delhi-based volunteer group, is having a clean-up drive on Saturday at 7.30am. “We are calling on volunteers to help us clean Brar Square, next to the Delhi Cantonment Metro Station. This entire stretch has a large number of food stalls, so it has been a victim of garbage dumping,” shares Manish Khurana, co-founder of WMTC...<a href="https://www.hindustantimes.com/cities/delhi-news/earthday-2022-city-youngsters-vow-to-go-green-101650618618809.html" style="color: #1ABC9C;" target="_blank"> Read More</a></p>
                   
                      <p>Hindustan Times</p>
                      <p>22-Apr-2022</p>
                   
                      <hr style=" border-top: 1px solid #1ABC9C">
                   </div>  

                   <div class=" mbr-text pb-3 mbr-fonts-style display-5 col-md-4"  style="font-size: large;text-align: left;">
                     
                     <p><b>Green crusaders pick the baton to keep Delhi clean</b></p>
                     <br>
                   <p>How many times have you strolled through the neigbourhood, seen a pile of garbage on the street-side, and wrinkled your nose, only to walk past it. For a majority of the population, the appalling state of our surroundings only elicits one reaction; that of disgust. But others like We Mean To Clean (WMTC) - a group of city-based volunteers - have willingly picked up the baton to keep the city clean...<a href="https://www.newindianexpress.com/cities/delhi/2021/sep/15/green-crusaders-pick-the-baton-to-keep-delhi-clean-2358935.html" style="color: #1ABC9C;" target="_blank"> Read More</a></p>
                
                   <p>The New Indian Express</p>
                   <p>15-Sep-2021</p>
                
                   <hr style=" border-top: 1px solid #1ABC9C">
                </div>  


                        <!-- <p><b>Swachh warriors: Delhi’s unique spot-fixers fight to keep Capital clean</b></p>
                        <p>“ We believe we need to clean up our cities ourselves rather than keep cribbing about the system or the civic agencies. We feel such partnership with citizens and civic authorities is the best way to clean and keep our cities clean ,” says Swati Bhalla, founding volunteer of the Delhi-based We Mean To Clean...<a href="http://www.hindustantimes.com/delhi-news/swachh-warriors-delhi-s-unique-spot-fixers-fight-to-keep-capital-clean/story-S8Klj255QdCA3rcz9y1gWK.html" style="color: #1ABC9C;" target="_blank"> Read More</a></p>
                     
                        <p>Hindustan Times</p>
                        <p>21-May-2017</p>
                     
                        <hr style=" border-top: 1px solid #1ABC9C">
                     </div>      

                  <div class=" mbr-text pb-3 mbr-fonts-style display-5 col-md-4"  style="font-size: large;text-align: left;">
                  
                     <p><b>Once a dumping ground, Noida City Centre Metro Station wall gets a swachh makeover</b></p>
                  
                     <p> The Noida Authority workers removed the layers of garbage at the spot, after which, the volunteers started removing the posters. "While we were working here, people kept coming and asking what were we doing. They started taking interest in the activity," said Manish Khurana, another founder of We Mean to Clean...<a href="http://timesofindia.indiatimes.com/city/noida/once-a-dumping-ground-noida-city-centre-metro-station-wall-gets-a-swachh-makeover/articleshow/58478036.cms" style="color: #1ABC9C;" target="_blank"> Read More</a></p>
                     <p>Times of India</p>
                        <p>03-May-2017</p>
                     <hr style=" border-top: 1px solid #1ABC9C">
                  </div>
                    
                  <div class=" mbr-text pb-3 mbr-fonts-style display-5 col-md-4"  style="font-size: large;text-align: left;">
                  
                     <p><b>Two MNC Professionals Are Cleaning up Delhi & NCR, One Dirty Pocket at a Time</b></p>
                  
                     <p>Swati Bhalla and Manish Khurana are on a mission – they want to clean up Delhi and NCR. And they mean business. After all, since 2014 they have conducted over 30 cleanliness drives across the region, transforming entire pockets of land into small havens. But to hear them talk, you realise that they have only...<a href="http://www.thebetterindia.com/99656/clean-delhi-ncr-civic-duty/" style="color: #1ABC9C;" target="_blank"> Read More</a></p>
                     <p>The Better India</p>
                     <p>08-May-2017</p>
                     <hr style=" border-top: 1px solid #1ABC9C"> 
                  </div> -->
               
                 </div>
             </div>
             <!-- <div class="mbr-section-btn align-center">
               <a class="btn btn-md display-3" href="#news" style="background-color: #1ABC9C; color: white;"><b>More News</b></a>
               </div> -->
      
               <div class="mbr-white col-md-12">
                  <p class="mbr-text pb-3 mbr-fonts-style display-5" style="font-size: large; color: #1ABC9C; text-align: center;">
                     <b>Our Newsroom is under construction. It will be live soon!</b>
                  </p>
               </div>
      
      </section>

      <section class="mbr-section form1 cid-swfEXXThIV" id="joinUs" style="background: #1ABC9C;">

         <div class="container align-center">
             <div class="row justify-content-md-left">
                <div class="mbr-white col-md-10">
                   <h1 class="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1" style="color: white; text-align: left;">Join Us</h1>
                </div>
                  <br><br><br><br>
                  
                  <div class="mbr-white col-md-12">
                    <p class="mbr-text pb-3 mbr-fonts-style display-5" style="font-size: large;color: white; text-align: left;">
                      <img src="assets/images/6.png" style="float:left; width:130px;height:auto;margin-right: 35px;">
                     <a target="blank" href="https://www.meetup.com/We-Mean-To-Clean/ "><strong><u>Meetup.com</u></strong></a> 
                      is our primary platform for registration of members and scheduling of events - spotfixes,
                      cleanliness drives, awareness programs etc. You'll be taken to
                     <u><a target="blank" href="https://www.meetup.com/We-Mean-To-Clean/"><strong>our meetup page</strong></a></u>
                       on this platform and you can sign up there. 
                       The site will ask you to activate your account by verifying your email ID. Once you're done, 
                       you'll have our joining form with a few basic questions. It's all very simple!
                  </p>           
                 </div>
                    
                 </div>
             </div>
       
       </section>

       <!-- <section class="carousel slide cid-t9SP1H3dsY" data-interval="false" id="slider2-0">

    
         <div class="container content-slider">
             <div class="content-slider-wrap">
                 <div><div class="mbr-slider slide carousel" data-keyboard="false" data-ride="false" data-interval="false" data-pause="false">
                  <ol class="carousel-indicators">
                     <li data-app-prevent-settings="" data-target="#slider2-0" class=" active" data-slide-to="0"></li>
                     <li data-app-prevent-settings="" data-target="#slider2-0" data-slide-to="1"></li>
                     <li data-app-prevent-settings="" data-target="#slider2-0" data-slide-to="2"></li>
                  </ol>
                  <div class="carousel-inner" role="listbox">
                     <div class="carousel-item slider-fullscreen-image active" data-bg-video-slide="false" style="background-image: url(assets/images/1.jpg);">
                        <div class="container container-slide"><div class="image_wrapper">
                           <div class="mbr-overlay"></div>
                           <img src="assets/images/1.jpg" alt="" title="">
                           <div class="carousel-caption justify-content-center">
                              <div class="col-10 align-center"><p class="lead mbr-text mbr-fonts-style display-7">Choose from the large selection of latest pre-made blocks - jumbotrons, hero images, parallax scrolling, video backgrounds, hamburger menu, sticky header and more.</p></div></div></div></div></div>
                              <div class="carousel-item slider-fullscreen-image" data-bg-video-slide="false" style="background-image: url(assets/images/2.jpg);">
                                 <div class="container container-slide"><div class="image_wrapper"><div class="mbr-overlay"></div>
                                 <img src="assets/images/2.jpg" alt="" title=""><div class="carousel-caption justify-content-center">
                                    <div class="col-10 align-center"><p class="lead mbr-text mbr-fonts-style display-7">Slide with youtube video background and color overlay. Title and text are aligned to the left.</p></div></div></div></div></div>
                                    <div class="carousel-item slider-fullscreen-image" data-bg-video-slide="false" style="background-image: url(assets/images/3.jpg);">
                                       <div class="container container-slide"><div class="image_wrapper"><div class="mbr-overlay"></div>
                                       <img src="assets/images/3.jpg" alt="" title=""><div class="carousel-caption justify-content-center">
                                          <div class="col-10 align-center"><p class="lead mbr-text mbr-fonts-style display-7">Choose from the large selection of latest pre-made blocks - jumbotrons, hero images, parallax scrolling, video backgrounds, hamburger menu, sticky header and more.</p></div></div></div></div></div></div>
                                          <a data-app-prevent-settings="" class="carousel-control carousel-control-prev" role="button" data-slide="prev" href="#slider2-0"><span aria-hidden="true" class="mbri-left mbr-iconfont"></span>
                                             <span class="sr-only">Previous</span></a>
                                             <a data-app-prevent-settings="" class="carousel-control carousel-control-next" role="button" data-slide="next" href="#slider2-0">
                                                <span aria-hidden="true" class="mbri-right mbr-iconfont"></span>
                                                <span class="sr-only">Next</span></a></div></div> 
             </div>
         </div>
     </section> -->
   
 <section once="footers" class="cid-swfFAP9Cvk" id="footer6-9">

      <div class="container">
          <div class="media-container-row align-center mbr-white">
              <div class="col-12">
               <p class="mbr-text mb-0 mbr-fonts-style display-7">
               We're undergoing maintenance, we'll be updating our website soon! </p><br>
                  
              </div>
              
          </div>
      </div>
  </section>
         