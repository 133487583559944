import { Component, OnInit, Input, ViewChild} from '@angular/core';
import {apiCalls} from '../app/app.component.service';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

// export class NgbdCarouselPause {
//   images = [62, 83, 466, 965, 982, 1043, 738].map((n) => `https://picsum.photos/id/${n}/900/500`);

//   paused = false;
//   unpauseOnArrow = false;
//   pauseOnIndicator = false;
//   pauseOnHover = true;
//   pauseOnFocus = true;

//   @ViewChild('carousel', { static: true })
//   carousel!: NgbCarousel;

//   togglePaused() {
//     if (this.paused) {
//       this.carousel.cycle();
//     } else {
//       this.carousel.pause();
//     }
//     this.paused = !this.paused;
//   }

//   onSlide(slideEvent: NgbSlideEvent) {
//     if (this.unpauseOnArrow && slideEvent.paused &&
//       (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
//       this.togglePaused();
//     }
//     if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
//       this.togglePaused();
//     }
//   }
// }

export class AppComponent implements OnInit{
  title = 'wmtc';
  errorMessage:string="";
  successMessage:string="";
  meetupList:any[]=[];
  join:Boolean=false;
  lat:any;
  lon:any;
 
  caption = ["WM - We conduct waste management awareness programs and also help implement/ execute the same.",
  "T - Native tree plantation and we also water our saplings regularly.",
  "C - Cleaning a public place that's a victim of open garbage dumping/ open urination/ defecation."]

  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = false;
  pauseOnFocus = true;
  showNavigationArrows = false;
  showNavigationIndicators = false;

  @ViewChild('carousel', { static: true })
  carousel!: NgbCarousel;

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }

constructor(private meetupService:apiCalls){}

  ngOnInit():void{
   this.meetupService.getMeetupDetails().subscribe(res=>{
   this.meetupList.push(res);
  //  console.log(this.meetupList.length);
  //  console.log(this.meetupList[0].data[0].description.replaceAll('\\',''));
  // this.lat=this.meetupList[0].data[0].venue.lat;
  // this.lon=this.meetupList[0].data[0].venue.lon;
  // console.log(this.lat);
  // console.log(this.lon);
  //this.join=false;
  //console.log(this.join);
})
  }

  // showJoinPage(){
  //   this.join=true;
  //   //console.log(this.join);
  // }

  // showLandingPage(){
  //   this.join=false;
  //   //console.log(this.join);
  // }
}
