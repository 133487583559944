import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { JoinusComponent } from './joinus/joinus.component';

const routes: Routes = [
  {
    path:'join',
    component:JoinusComponent
  }
  
];

// const routerOptions: ExtraOptions = {
//   scrollPositionRestoration: 'enabled',
//   scrollOffset: [0, 64],
// };

@NgModule({
  // imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
