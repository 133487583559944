<section class="cid-swfEH7rnkz mbr-fullscreen mbr-parallax-background" id="joinsec1">

    <div class="mbr-overlay" style="opacity: 0.8; background-color: rgb(35, 35, 35);"></div>
 
    <div class="container align-left">
       <div class="row justify-content-md-center">
          <div class="mbr-white col-md-10">
             <h1 class="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1">
                Why Volunteer?
               </h1>
 
             <p class="mbr-text pb-3 mbr-fonts-style display-5">
               Volunteering has immense benefits. Some of them are as follows:
             </p>
             <ul class="mbr-text pb-3 mbr-fonts-style display-5" style="list-style-type:disc;">
               <li>Volunteering helps you build/ hone soft skills such as teamwork, coordination, communication, 
                  listening and conflict management. They not only make you a better individual but also add a great 
                  value to your resume. </li>
                  <br>
                 <li>Volunteering helps make new friends and expand your social as well as professional network. 
                    This increased socializing activity also helps reduce stress and anxiety. Additionally, 
                    it helps in becoming more emotionally stable.</li>
                    <br>
                 <li>Most importantly, volunteering gives a sense of purpose in life. 
                    An objective that helps you lead a meaningful life while also making a positive impact on the lives of others.</li>
               </ul>
          </div>
       </div>
    </div>
 </section>
 
 
 <section class="mbr-section form1 cid-swfEXXThIV" id="joinsec2">
 
    <div class="container align-center">
        <div class="row justify-content-md-left">
            <div class="mbr-white col-md-10">
                <h1 class="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1" style="color: #1ABC9C; text-align: left;">Why volunteer with us?</h1>
             </div>
             <br><br><br>

             <div class="mbr-white col-md-12">
               <p class="mbr-text pb-3 mbr-fonts-style display-5" style="color: black; text-align: left;">
                 
                    Volunteering with We Mean To Clean will expose you to a diverse range of people: </p>
                    <ul class="mbr-text pb-3 mbr-fonts-style display-5" style="color: black;text-align: left;list-style-type:disc;">
                     <li>MBAs; corporate professionals
                     </li>
                     <li>Software and mechanical engineers
                     </li>
                     <li>Defence </li>
                     <li> Lawyers</li>
                     <li>Teachers </li>
                     <li>Chefs </li>
                     <li>Artists </li>
                     <li>College/School Students </li>
                     
                     </ul>
                         
                        <p class="mbr-text pb-3 mbr-fonts-style display-5" style="color: black; text-align: left;">
                       Such exposure helps you build different perspectives on a given situation to help you improve
                           your thinking and problem solving ability.
                     
                          <br> <br>
                       Whether you wish to develop/ improve your skill across project management, negotiation, people management, 
                          content writing, social media marketing, waste management know-how, knowledge of Delhi’s native trees or if 
                          you simply wish to do your bit for soul-satisfaction, we have something for everyone!
                     
                     <br> <br>
                     Moreover, we also have access to domain experts in our areas of operations. So, we work in an educated manner 
                        allowing all of us to take scientifically-correct steps.
                   
                   <br> <br>
                  We Mean To Clean also provides certificates to volunteers as per a defined set of terms and conditions.
                   
               </p>
               
            </div>
            </div>
        </div>
 
 </section>
 

 <section class="mbr-section form1 cid-swfEXXThIV" id="joinsec3" style="background: #1ABC9C;">

   <div class="container align-center">
       <div class="row justify-content-md-left">
           <div class="mbr-white col-md-10">
               <h1 class="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1" style="color: white; text-align: left;">Joining us</h1>
            </div>
            <br><br><br>

            <div class="mbr-white col-md-12">
              <p class="mbr-text pb-3 mbr-fonts-style display-5" style="color: white; text-align: left;">
                
               <a target="blank" href="https://www.meetup.com/We-Mean-To-Clean/ " style="color: white;"><strong><u>Meetup.com</u></strong></a> 
                is our primary platform for registration of members and scheduling of events - spotfixes,
                cleanliness drives, awareness programs etc. You'll be taken to
               <u><a target="blank" href="https://www.meetup.com/We-Mean-To-Clean/ " style="color: white;"><strong>our meetup page</strong></a></u>
                 on this platform and you can sign up there. 
                 The site will ask you to activate your account by verifying your email ID. Once you're done, 
                 you'll have our joining form with a few basic questions. It's all very simple!
            </p>
                   
              
           </div>
              
           </div>
       </div>

</section>

 