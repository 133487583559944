import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Pipe({
  name: "safe",
  pure: true
})
export class SafePipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  public transform(value: any): SafeResourceUrl {
    // console.log(`Pipe Called!`);
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}